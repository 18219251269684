export const Brands = [
  {
    name: 'Hello Molly',
    href: '/collections/exclusive/brand_hello-molly-exclusive',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/24ebffbd33231132882fea3e6761270e.png?v=1659423490',
  },
  {
    name: 'Hello Molly Swim',
    href: '/collections/swim/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/hello-molly-swim.png?v=1620277305',
  },
  {
    name: 'Dear Emilia',
    href: '/collections/dear-emilia/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/dear-emilia.png?v=1620009796',
  },
  {
    name: 'UNDERCOVER Style Helpers',
    href: '/collections/undercover-style-helpers/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/7564cfdc8460201933d0d04b1fadbf43.png?v=1620000068',
  },
  {
    name: 'Billini',
    href: '/collections/billini/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/b736b3c52c4092fb41e06e46840c4274.png?v=1620000017',
  },
  {
    name: 'Bondi Sands',
    href: '/collections/bondi-sands/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/1ffb3fe39f32c85bdce7700946b4ba7f.png?v=1620000021',
  },
  {
    name: 'Lee',
    href: '/collections/lee/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/5a511646b28d0c84b6d7cff1db9e3a08.png?v=1620000042',
  },
  {
    name: 'Lioness',
    href: '/collections/lioness-dress/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/6f10377ab5d47ddff256cd354a0cd785.png?v=1620000042',
  },
  {
    name: 'Loving Tan',
    href: '/collections/loving-tan/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/c8ec55b863133b5d08cae7fe75cec89b.png?v=1620000042',
  },
  {
    name: 'Mermade Hair',
    href: '/collections/mermade-hair/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/mermade-hair.png?v=1631606439',
  },
  {
    name: 'Riders By Lee',
    href: '/collections/riders-by-lee/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/d77b1e8c2a4b7c20dcf57ed26d8f32ad.png?v=1620000049',
  },
  {
    name: 'Runaway',
    href: '/collections/runaway/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/34c3d4de5ca5e370d1902bdd0c366b28.png?v=1620000053',
  },
  {
    name: 'Slick Hair Company',
    href: '/collections/slick-hair-company/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/slick-hair-company.png?v=1620009978',
  },
  {
    name: 'Smile Makers',
    href: '/collections/smile-makers/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/9fb7686fe10ed09b6c9d5fd829d92838.png?v=1620000059',
  },
  {
    name: 'SNDYS',
    href: '/collections/sndys/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/3b8a76ca8139b64921e072c7e609139f.png?v=1620000061',
  },
  {
    name: 'Sunnylife',
    href: '/collections/sunnylife/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/073ff60a31f3ddc9e259587f9533c84a.png?v=1620000060',
  },
  {
    name: 'Verali',
    href: '/collections/verali/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/a2b29dbc93b023311a470299938d1bca.png?v=1620000067',
  },
  {
    name: 'Wrangler',
    href: '/collections/wrangler/',
    image: 'https://content.hellomolly.com.au/cdn/shop/collections/242f665855a3b148cc74d839eef6f9c3.png?v=1620000073',
  },
  {
    name: "Levi's",
    href: '/collections/levis/',
    image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/collections/3_1.png?v=1715920932',
  },
  {
    name: 'Banbe',
    href: '/collections/banbe/',
    image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/collections/BANBE-LOGO.png?v=1715920948',
  },
  {
    name: 'Peta & Jain',
    href: '/collections/peta-jain/',
    image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/collections/2_2.png?v=1715920962',
  },
];

export const FeaturedBrands = [
  {
    name: 'Smile Makers',
    href: '/collections/smile-makers/',
    image: 'https://cdn.shopify.com/s/files/1/0518/3687/9007/files/Featured-Brand-Smile-Makers.jpg?v=1621493603',
  },
  {
    name: 'Undercover Style Helpers',
    href: '/collections/undercover-style-helpers/',
    image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Featured-Brand-Undercover.jpg?v=1642725695',
  },
  {
    name: 'Swim by Hello Molly',
    href: '/collections/swim/',
    image: 'https://cdn.shopify.com/s/files/1/0518/3687/9007/files/Featured-Brand-HM-Swim.jpg?v=1621493507',
  },
];

export const BrandsList: Array<string> = [
  'BILLINI',
  'BONDI SANDS',
  'DEAR EMILIA',
  'HELLO MOLLY',
  // 'SWIM BY HELLO MOLLY',
  'LEE',
  'LIONESS TAN',
  'LOVING TAN',
  'MERMADE HAIR',
  'RIDERS BY LEE',
  'RUNAWAY',
  'SLICKHAIR COMPANY',
  'SMILE MAKERS',
  'SNDYS',
  'SUNNYLIFE',
  'UNDERCOVER STYLE HELPERS',
  'VERALI',
  'WRANGLER',
];
